// @ts-nocheck
/* eslint-disable */

import {v4} from 'uuid';
import {logger} from './Logger';
// import Dropdown from 'bootstrap/js/dist/dropdown.js';
// import ApexCharts from 'apexcharts';
// import client from '../../Api/HttpClient';
// import {showDialog} from './ModalUtil';
// import ShareResultComponent from '../../Components/Platform/SharedResults/ShareResultComponent';
// import {trans as utilTrans} from './I18nUtil';

// Assuming the existence of a logger instance with an info method

export function uuid(): string {
  return v4();
}

export function isDevMode(): boolean {
  return process.env.NEXT_PUBLIC_DEV_MODE === 'true';
}

// Assuming `object` can be any type
export function copyObject<T>(object: T | null): T | null {
  if (!object) return object;
  return JSON.parse(JSON.stringify(object));
}

export function getContrastText(hexColor: string): 'black' | 'white' {
  // Check if hex color is dark
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

export function debug(message: string): void {
  logger.debug({message});
  if (localStorage.enableDebugger) {
    // eslint-disable-next-line no-debugger
    debugger;
  }
}

export function selectedKeys(items: Record<string, any>): string[] {
  return Object.entries(items)
    .filter(([k, v]) => v)
    .map(([k, v]) => k);
}

// Object.entries(steps.selectServices.results).filter(([k, v]) => v).map(([k, v]) => k)

export function getCurrentAPIBasePath(): string {
  return process.env.NEXT_PUBLIC_API_URL ?? '';
}

export function getCurrentFrontendBasePath() {
  return process.env.NEXT_PUBLIC_WEB_URL ?? '';
}

export function isProduction() {
  return process.env.NEXT_PUBLIC_API_URL === 'https://my.softfacts.work';
}

// TODO @Sherif @Stefan decide which of the following functions are needed

//
// window.toggleDescription = window.toggleExplanation;
//
// window.Swal = Swal;
//
// window.dragula = dragula;
//

// TODO @Stefan @Sherif And these as well

// // TODO @Stefan @Sherif Is there a better way for this? Without this, users have to click twice on the submit buttons
// export function delayedBlur(stillActive, cb) {
//   return e => {
//     setTimeout(() => {
//       if (cb && stillActive && stillActive()) {
//         cb(e);
//       }
//     }, 300);
//   };
// }

export function isMobileDevice(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return true;
  }

  if (/android/i.test(userAgent)) {
    // If it's Android and has a screen width less than 768, it's likely a phone
    return window.innerWidth < 768;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    // iOS devices below the iPad Mini have a screen width less than 768
    return window.innerWidth < 768;
  }

  return false;
}

//
// // == LEGACY JAVASCRIPT ==
// window.togglePasswordVisibility = function (targetId) {
//   const passwordInput = document.getElementById(targetId);
//   const eyeIcon = document.getElementById(`eye-icon-${targetId}`);
//   if (passwordInput.type === 'password') {
//     passwordInput.type = 'text';
//     eyeIcon.classList.remove('fa-eye');
//     eyeIcon.classList.add('fa-eye-slash');
//   } else {
//     passwordInput.type = 'password';
//     eyeIcon.classList.remove('fa-eye-slash');
//     eyeIcon.classList.add('fa-eye');
//   }
// };
//
// window.toggleExplanation = function (icon, id) {
//   const element = document.getElementById(id);
//   const {display} = element.style;
//   if (display === 'block') {
//     element.style.display = 'none';
//     icon.classList.remove('fa-angle-up');
//     icon.classList.add('fa-angle-down');
//   } else {
//     element.style.display = 'block';
//     icon.classList.remove('fa-angle-down');
//     icon.classList.add('fa-angle-up');
//   }
// };

//
// function initSparkChart(chart) {
//   // Options
//   const options = {
//     chart: {
//       width: '100%',
//       sparkline: {
//         enabled: true,
//       },
//     },
//     series: [],
//     labels: [],
//     stroke: {
//       width: 2,
//       curve: 'smooth',
//     },
//     markers: {
//       size: 0,
//     },
//     colors: [],
//     tooltip: {
//       fixed: {
//         enabled: false,
//       },
//       x: {
//         show: false,
//       },
//       y: {
//         title: {
//           formatter(e) {
//             return '';
//           },
//         },
//       },
//       marker: {
//         show: !1,
//       },
//     },
//   };
//
//   // Get data from data attributes
//   const dataset = chart.dataset?.dataset;
//   const labels = chart.dataset?.labels;
//   const color = chart.dataset?.color;
//   const height = chart.dataset?.height;
//   const type = chart.dataset?.type;
//
//   // Inject synamic properties
//   options.series = [
//     {
//       data: JSON.parse(dataset),
//     },
//   ];
//
//   if (labels) {
//     options.labels = [labels];
//   }
//
//   const colorMap = {
//     primary: '#ff5c5c',
//     info: '#00b8d9',
//     success: '#00cc7e',
//     danger: '#ed6964',
//     warning: '#f6ae28',
//     dark: '#273444',
//   };
//
//   options.colors = [colorMap[color] ? colorMap[color] : '#00cc7e'];
//
//   options.chart.height = height || 35;
//
//   options.chart.type = type || 'line';
//
//   // Init chart
//   var chart = new ApexCharts(chart, options);
//
//   // Draw chart
//   setTimeout(() => {
//     chart.render();
//   }, 300);
// }
//
// // add event handler for htmlReady (after htmlWrapper component is loaded)
// addReactEventListener('htmlReady', event => {
//   if (event.detail.target) {
//     // Dropdowns
//     event.detail.target.querySelectorAll('[data-toggle="dropdown"]').forEach(element => {
//       const dropdown = new Dropdown(element);
//       element.addEventListener('click', event => {
//         event.preventDefault();
//         dropdown.toggle();
//       });
//     });
//
//     // Spark charts
//     event.detail.target.querySelectorAll('[data-toggle="spark-chart"]').forEach(chart => {
//       initSparkChart(chart);
//     });
//   }
// });
//
// window.reloadHtmlWrapper = function (target) {
//   dispatchReactEvent('reloadHtml', {target});
//   dispatchReactEvent('reloadLocation', {target});
// };
//
// window.legacyAjaxCall = async function (url) {
//   const {data} = await httpClient.get(url);
//   if (data['success.message']) {
//     window.snackbar.success({message: data['success.message'].title});
//   } else if (data['error.message']) {
//     window.snackbar.danger({message: data['error.message']});
//   }
// };
//
// window.shareResult = function (type, project) {
//   shareResult(type, project);
// };
//
// export function shareResult(type, project) {
//   let onConfirm = null;
//   showDialog({
//     component: <ShareResultComponent onInitialized={e => (onConfirm = e.onConfirm)} type={type} project={project} />,
//     callbackFn: async (confirmed, dialog) => {
//       if (onConfirm) {
//         return await onConfirm(confirmed, dialog);
//       }
//       return false;
//     },
//     confirmationText: utilTrans('shared_results.share_notification_button'),
//     cancelText: utilTrans('base.cancel'),
//   });
// }
//
// window.showDeleteConfirm = hasSubscription => {
//   Swal.fire({
//     title: utilTrans('profile.delete_confirm_popup.title'),
//     html: hasSubscription
//       ? utilTrans('profile.delete_confirm_popup.description_with_subscription')
//       : utilTrans('profile.delete_confirm_popup.description_with_out_subscription'),
//     confirmButtonText: utilTrans('profile.delete_confirm_popup.confirm_btn'),
//     confirmButtonClass: 'swal2-primary',
//     showCloseButton: true,
//     allowOutsideClick: false,
//     showCancelButton: true,
//     cancelButtonClass: 'swal2-secondary',
//     cancelButtonText: utilTrans('profile.delete_confirm_popup.cancel_btn'),
//     customClass: {
//       container: 'swal-container-left-align',
//       title: 'swal-title-left-align',
//       content: 'swal-content-left-align',
//       htmlContainer: 'swal-html-container-left-align',
//     },
//     width: '700px',
//   }).then(result => {
//     if (result.isConfirmed) {
//       window.onFormSubmit();
//     }
//   });
// };
